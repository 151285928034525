import { useStaticQuery, graphql } from "gatsby";
import data from "./data";

const useData = () => {
  const images = useStaticQuery(graphql`
    fragment FixedImageMedium on File {
      childImageSharp {
        fixed(width: 65, grayscale: false) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    query {
      firstVisit: file(relativePath: { eq: "etc/information.png" }) {
        ...FixedImageMedium
      }
      patientForm: file(relativePath: { eq: "etc/exam.png" }) {
        ...FixedImageMedium
      }
      paymentInfo: file(relativePath: { eq: "etc/credit-card.png" }) {
        ...FixedImageMedium
      }
      emergencies: file(relativePath: { eq: "etc/medicine.png" }) {
        ...FixedImageMedium
      }
      food: file(relativePath: { eq: "etc/bad-habits.png" }) {
        ...FixedImageMedium
      }
      faq: file(relativePath: { eq: "etc/faq.png" }) {
        ...FixedImageMedium
      }
    }
  `);

  return data.map((item) => ({
    ...item,
    image: images[item.id].childImageSharp.fixed,
  }));
};

export default useData;
