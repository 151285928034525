import React from "react";
import Layout from "../../components/layout";
import { PatientInfo } from "../../components/patient-info";
import SEO from "../../components/seo";

const Services = () => (
  <Layout>
    <SEO title="Our patient information" />
    <PatientInfo />
  </Layout>
);

export default Services;
