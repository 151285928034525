import React from "react";
import Img from "gatsby-image";

import {
  StyledCard,
  ImageWrapper,
  Title,
  LinkButton,
  StyledLink,
} from "./styles";

const Card = ({ title, link, image }) => {
  return (
    <StyledCard>
      <StyledLink to={link}>
        <ImageWrapper>
          <Img fixed={image} />
        </ImageWrapper>
        <Title>{title}</Title>
        <LinkButton to={link}>More info</LinkButton>
      </StyledLink>
    </StyledCard>
  );
};

export default Card;
