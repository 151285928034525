type Data = Array<{
  id: string;
  title: string;
  link: string;
}>;

const data: Data = [
  {
    id: "firstVisit",
    title: "Your First Orthodontic Visit",
    link: "/patient-information/first-visit",
  },
  {
    id: "patientForm",
    title: "Patient Form",
    link: "/patient-information/patient-form",
  },
  {
    id: "paymentInfo",
    title: "Payment Information",
    link: "/patient-information/payment-information",
  },
  {
    id: "emergencies",
    title: "Emergencies",
    link: "/patient-information/emergencies",
  },
  {
    id: "food",
    title: "Foods And Habits to Avoid",
    link: "/patient-information/foods-and-habits-to-avoid",
  },
  {
    id: "faq",
    title: "Frequently Asked Questions",
    link: "/patient-information/faq",
  },
];

export default data;
