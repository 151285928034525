import React from "react";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import Card from "./Card";
import { Container, Wrapper } from "./styles";
import useData from "./useData";

export const PatientInfo = () => {
  const data = useData();

  return (
    <>
      <Breadcrumb pageTitle="Patient Information">
        <BreadcrumbItem Link="patient-information" Text="Patient Information" />
      </Breadcrumb>
      <PageContainer>
        <Container>
          <Wrapper>
            {data.map((item) => (
              <Card key={item.id} {...item} />
            ))}
          </Wrapper>
        </Container>
      </PageContainer>
    </>
  );
};
